// A component that contains the main content of the main page.

import React, {useEffect, useState} from "react";

function VoorbereidingMainContent() {

    // State to hold the current language
    const [language, setLanguage] = useState('nl'); // Default to English

    // Effect to read the language setting from localStorage on initial load
    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            setLanguage(storedLanguage);
        }
    }, []);

    if (language === 'nl') {
        return (
            <div className="main-background-agenda">
                <div className="agenda-container">
                    <div className="contact-container">
                        <h2 className="contact-h2">Voorzorg</h2>
                        <p className='main-content-informatie-text'>Wanneer je Ayahuasca gaat drinken is het heel erg
                            belangrijk dat je van tevoren gevast hebt. De Mao-remmende werking vraagt om aangepaste
                            voeding,
                            minstens drie dagen van tevoren. De combinatie met bepaalde antidepressiva en andere
                            gevoelsonderdrukkers moet tegen elke prijs vermeden worden en kan levensgevaarlijk zijn.
                            Daarnaast is er een indrukwekkende lijst van stoffen die misselijkheid of hoofdpijn kunnen
                            veroorzaken.</p>
                        <br></br>
                        <h2 className='contact-h2'>Gevaarlijke stoffen</h2>
                        <p className='voorbereiding-informatie-text'>
                            ● slaapmiddelen<br></br>
                            ● narcosemiddelen<br></br>
                            ● medicijnen tegen migraine en allergieën<br></br>
                            ● antidepressiva die selectieve serotonine heropnameremmers (SSRI’s) bevatten, zoals het
                            kruid
                            Kanna (Sceletium tortuosum) en de medicijnen paroxetine (Seroxat), fluoxetine (Prozac),
                            citalopram (Cipramil), fluvoxamine (Fevarin) en sertraline (Zoloft)<br></br>
                            ● cocaïne<br></br>
                            ● mescaline cacti (peyote en san pedro)<br></br>
                            ● amfetamine (speed)<br></br>
                            ● MDMA (XTC)<br></br>
                            ● alcohol<br></br>
                            ● ephedra/ephedrine (Xtenzion, Ultra Boost, Superstacker, Ephedra Supercaps, etc)<br></br>
                            ● pseudo-ephedrine<br></br>
                            ● macromerine<br></br>
                            ● phentermine<br></br>
                        </p>
                        <br></br>
                        <h2 className='contact-h2'>Stoffen die misselijkheid of hoofdpijn kunnen veroorzaken</h2>
                        <p className='voorbereiding-informatie-text'>
                            ● bewerkte zuivelproducten (yoghurt, zure room en karnemelk);<br></br>
                            ● oude en overjarige kaas, boerenkaas of andere ongepasteuriseerde kaassoorten (kwark en
                            jonge kaas toegestaan)<br></br>
                            ● gedroogde en gefermenteerde worst (salami, cervelaat, corned beef, lever, etc)<br></br>
                            ● vlees, vis en eieren die niet vers zijn<br></br>
                            ● zure haring en met zout gedroogde vis<br></br>
                            ● vleesextracten<br></br>
                            ● gistproducten zoals bijv. Marmite (brood is toegestaan)<br></br>
                            ● zuurkool<br></br>
                            ● fruit (vijgen, bananen, ananas, avocado’s, rozijnen, pruimen en frambozen)<br></br>
                            ● pinda’s en andere noten<br></br>
                            ● tuinbonen, peultjes, sojabonen, linzen, etc<br></br>
                            ● sojasaus<br></br>
                            ● LSA (morning glory en baby Hawaiian woodrose zaadjes)<br></br>
                            ● aan MDA verwante kruiden (kalmoes en nootmuskaat)<br></br>
                            ● chocola<br></br>
                            ● cafeïne bevattende producten (koffie, thee, cola, energiedrankjes, etc)<br></br>
                            ● guarana<br></br>
                            ● ginseng<br></br>
                            ● St. Janskruid<br></br>
                            ● neussprays (Vicks Sinex, Prevalin of Otrivin)<br></br>
                            ● andere MAO-remmende producten.<br></br>
                        </p>
                        <br></br>
                        <h2 className='contact-h2'>Aan te bevelen dieet:</h2>
                        <p className='main-content-informatie-text'>Een aantal dagen vegetarisch eten en/of sap- of
                            soepvasten is een prima voorbereiding en maakt je ontvankelijker voor de ervaring die je te
                            wachten staat. De soepen dienen vegetarisch te zijn en je kan zelf allerlei varianten maken.
                            Maar ook smoothies, salades, fruit, noten, roerbakgroente, rijstcrackers, broodje jonge kaas
                            zijn mogelijk.
                            <br></br><br></br>
                            Op <a className='voorbereiding-informatie-text-hyperlink'
                                  href='https://soepboek.com'>soepboek.com</a> vind je enkele geschikte soeprecepten</p>
                    </div>
                </div>
            </div>
        );
    } else if (language === 'en') {
        return (
            <div className="main-background-agenda">
                <div className="agenda-container">
                    <div className="contact-container">
                        <h2 className="contact-h2">Precaution</h2>
                        <p className='main-content-informatie-text'>When you start drinking Ayahuasca, it is very
                            important that you have fasted beforehand. The Mao-inhibiting effect requires adjusted
                            nutrition, at least three days in advance. The combination with certain antidepressants and
                            other emotional suppressants should be avoided at all costs and can be life-threatening. In
                            addition, there is an impressive list of substances that can cause nausea or headaches.</p>
                        <br></br>
                        <h2 className='contact-h2'>Hazardous Substances</h2>
                        <p className='voorbereiding-informatie-text'>
                            ● sleeping pills<br></br>
                            ● anesthetics<br></br>
                            ● medications for migraines and allergies<br></br>
                            ● antidepressants containing selective serotonin reuptake inhibitors (SSRIs), such as the
                            herb Kanna (Sceletium tortuosum) and the medications paroxetine (Seroxat), fluoxetine
                            (Prozac), citalopram (Cipramil), fluvoxamine (Fevarin), and sertraline (Zoloft)<br></br>
                            ● cocaine<br></br>
                            ● mescaline cacti (peyote and san pedro)<br></br>
                            ● amphetamine (speed)<br></br>
                            ● MDMA (Ecstasy)<br></br>
                            ● alcohol<br></br>
                            ● ephedra/ephedrine (Xtenzion, Ultra Boost, Superstacker, Ephedra Supercaps, etc)<br></br>
                            ● pseudoephedrine<br></br>
                            ● macromerin<br></br>
                            ● phentermine<br></br>
                        </p>
                        <br></br>
                        <h2 className='contact-h2'>Substances that can cause nausea or headache</h2>
                        <p className='voorbereiding-informatie-text'>
                            ● processed dairy products (yogurt, sour cream and buttermilk);<br></br>
                            ● old and over-aged cheese, farmer's cheese or other unpasteurized cheeses (curd and young
                            cheese allowed)<br></br>
                            ● dried and fermented sausage (salami, cervelate, corned beef, liver, etc)<br></br>
                            ● meat, fish and eggs that are not fresh<br></br>
                            ● pickled herring and salt-dried fish<br></br>
                            ● meat extracts<br></br>
                            ● yeast products such as Marmite (bread is allowed)<br></br>
                            ● sauerkraut<br></br>
                            ● fruit (figs, bananas, pineapple, avocados, raisins, plums and raspberries)<br></br>
                            ● peanuts and other nuts<br></br>
                            ● broad beans, snow peas, soy beans, lentils, etc<br></br>
                            ● soy sauce<br></br>
                            ● LSA (morning glory and baby Hawaiian woodrose seeds)<br></br>
                            ● herbs related to MDA (calamus and nutmeg)<br></br>
                            ● chocolate<br></br>
                            ● caffeine-containing products (coffee, tea, cola, energy drinks, etc.)<br></br>
                            ● guarana<br></br>
                            ● ginseng<br></br>
                            ● St John's wort<br></br>
                            ● nasal sprays (Vicks Sinex, Prevalin or Otrivin)<br></br>
                            ● other MAO inhibitor products.<br></br>
                        </p>
                        <br></br>
                        <h2 className='contact-h2'>Recommended diet:</h2>
                        <p className='main-content-informatie-text'>Eating vegetarian food and/or juice or soup fasting
                            for a few days is an excellent preparation and makes you more receptive to the experience
                            that awaits you. The soups must be vegetarian and you can make all kinds of variations
                            yourself. But smoothies, salads, fruit, nuts, stir-fried vegetables, rice crackers, young
                            cheese sandwiches are also possible.
                            <br></br><br></br>
                            You will find some suitable soup recipes on <a
                                className='voorbereiding-informatie-text-hyperlink'
                                href='https://soepboek.com'>soepboek.com</a>.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default VoorbereidingMainContent;
