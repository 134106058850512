// A component that contains the main content of the main page.

import React, {useEffect, useState} from "react";
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import Button from "@mui/joy/Button";
import axios from 'axios';
import {Snackbar, Textarea} from "@mui/joy";
import {useForm, ValidationError} from "@formspree/react";

function ContactMainContent() {

    const [language, setLanguage] = useState('nl');
    const [open, setOpen] = useState(false);

 //   const [email, setEmail] = useState('');
 //   const [message, setMessage] = useState('');

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'nl');
        } else {
            setLanguage(localStorage.getItem('language'));
        }
    }, []);

    // old
//const ContactForm = () => {

//    const [state, handleSubmit] = useForm("xrgorbgv");

//    console.log(state)

//    if (state.succeeded) {
//        return <p>Thanks for joining!</p>;

//    }
//    return (
//        <form onSubmit={handleSubmit}>
//            <FormLabel sx={{fontSize: '20px'}}>Email</FormLabel>
//            <Input
//                id="email"
//                type="email"
//                name="email"
//            />
//            <ValidationError
//                prefix="Email"
//                field="email"
//                errors={state.errors}
//            />
//            <FormLabel sx={{fontSize: '20px'}}>Message</FormLabel>
//            <Textarea
//                sx={{width: '300px', height: '150px'}}
//                id="message"
//                name="message"
//            />
//            <ValidationError
//                prefix="Message"
//                field="message"
//                errors={state.errors}
//            />
//            <Button className='btn-contact'
//                    size="lg"
//                    variant='solid'
//                    color='success'
//                    sx={{marginTop: '35px', fontSize: '20px'}} type="submit" disabled={state.submitting}>
//                Submit
//            </Button>
//            <div className="g-recaptcha" data-sitekey="6LegsSEaAAAAANyi_jodGrAMi-fLqcTFSTbzpl-5"></div>
//        </form>
//    );
//};

    // new
    const ContactForm = () => {
        const [email, setEmail] = useState('');
        const [message, setMessage] = useState('');

        const tempContactFunction = (e) => {

            e.preventDefault();
            console.log(email, message);
            setOpen(true);

            axios.post('https://place-spokesman-cube-dependence.trycloudflare.com/api/sendMessage', {
                chatId: '586081089',
                message: `Email: ${email}\n\nMessage: ${message}`
            })
                .then((response) => {
                    console.log(response);
                }, (error) => {
                    console.log(error);
                });

        }

        return (
            <form onSubmit={tempContactFunction}>
                <FormControl>
                    <FormLabel sx={{fontSize: '20px'}}>Email</FormLabel>
                    <Input
                        id="email"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel sx={{fontSize: '20px'}}>Message</FormLabel>
                    <Textarea
                        sx={{width: '300px', height: '150px'}}
                        id="message"
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </FormControl>
                <Button
                    className='btn-contact'
                    size="lg"
                    variant='solid'
                    color='success'
                    sx={{marginTop: '35px', fontSize: '20px'}}
                    type="submit"
                >
                    Submit
                </Button>
            </form>
        );
    };

    if (language === 'nl') {
        return (
            <div className="main-background-agenda">
                <div className="agenda-container">
                    <div className="contact-container">
                        <h1 className="contact-h2">Contact us</h1>
                        <div className="contact-form-container">
                            <ContactForm />
                        </div>
                    </div>
                    <Snackbar
                        autoHideDuration={3000}
                        open={open}
                        size='lg'
                        color='success'
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpen(false);
                        }}
                    >
                        Het bericht is succesvol verzonden!
                    </Snackbar>
                </div>
            </div>
        );

    } else if (language === 'en') {
        return (
            <div className="main-background-agenda">
                <div className="agenda-container">
                    <div className="contact-container">
                        <h1 className="contact-h2">Contact us</h1>
                        <div className="contact-form-container">
                            <ContactForm/>
                        </div>
                    </div>
                    <Snackbar
                        autoHideDuration={3000}
                        open={open}
                        size='lg'
                        color='success'
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpen(false);
                        }}
                    >
                        Het bericht is succesvol verzonden!
                    </Snackbar>
                </div>
            </div>
        );
    }
}


export default ContactMainContent;
