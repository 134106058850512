// A component that contains the main content of the main page.

import React, {useEffect, useState} from "react";
import infoImage from "../Imgs/voorwie.jpg";
import Footer from "./FooterComponent";

function MainContentComponent() {

    // State to hold the current language
    const [language, setLanguage] = useState('nl'); // Default to English

    // Effect to read the language setting from localStorage on initial load
    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            setLanguage(storedLanguage);
        }
    }, []);

    const mainContentOnClick = () => {
        console.log("Button clicked");
        window.location.href = "/agenda";
    };

    if (language === 'nl') {
        return (
            <div className="main-background">
                <div className="main-content-rectangle">
                    <div className="main-content-container">
                        <h1 className="main-h1">
                            Ayahuasca Nederland
                            Healing Ceremonie
                        </h1>
                        <div className="main-buttons-container">
                            <div onClick={mainContentOnClick} className="btn-main-content">
                                <div className="btn-main-content-text">Book a ceremonie</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-content-information">
                    <div className="main-content-informatie-ceremonie-container">
                        <h2 className="main-content-informatie-h2">De ceremonie</h2>
                        <p className="main-content-informatie-text">Ayahuasca Nederland. Wij zijn de beste
                            aanbieder van Ayahuasca ceremonies, Niet alleen de Ayahuasca is belangrijk maar ook de setting
                            waarin de Ayahuasca gebruikt wordt. Daarnaast is een professionele voorbereiding en integratie
                            belangrijk zodat je een zo goed en compleet mogelijke ervaring krijgt. Onder leiding van zeer
                            ervaren begeleiders die al meer dan 12 jaar in Nederland en buitenland werken met de moederplant
                            en een gediplomeerde psycholoog bieden onze ceremonies een veilige, ondersteunende en
                            koesterende omgeving voor persoonlijke groei, heling en spirituele verkenning.
                            <br></br><br></br>Wat maakt dan het verschil met andere aanbieders; wij bieden een totaal
                            concept aan. Een gedegen psychologische uitleg over wat je te wachten staat. Sharing en
                            oefeningen om beter met jezelf en andere te verbinden. Professioneel ademwerk om alvast
                            energieblokkades op te ruimen zodat de Ayahuasca nog beter en dieper haar werk kan doen. Sharing
                            en professionele tips en trucs om het integratie proces goed en gedegen in gang te zetten.
                            <br></br><br></br>Ga op een transformerende reis met onze Ayahuasca-ceremonies en ontgrendel het
                            potentieel voor heling, zelfontdekking en persoonlijke groei. Sluit je bij ons aan terwijl we
                            ons verdiepen in de eeuwenoude wijsheid van Ayahuasca en de diepgaande mogelijkheden omarmen die
                            het biedt.
                            <br></br><br></br>Schrijf je vandaag nog in voor onze Ayahuasca healingweekenden in Nederland.
                        </p>
                    </div>
                    <div className="main-content-informatie-overons-container">
                        <h2 className="main-content-informatie-h2">Over ons</h2>
                        <p className="main-content-informatie-text">Na meer dan 12 jaar is de gewijde reis gestopt met het
                            faciliteren van Healingweekenden met ayahuasca. Wij hebben met ontzettend veel genoegen respect
                            ontzag en vanuit ons hart al die jaren deze ceremonies geleid en begeleidt. Vandaar dat wij deze
                            ceremonies voortzetten in de geest van Lars en Tanja Faber van www.degewijdereis.nl.
                            <br></br><br></br>Liefs, Petra, Tjeerd, Elena en Rob
                        </p>
                    </div>
                </div>

                <div className="main-content-information-second">
                    <div className="main-content-informatie-hoewerkt-container">
                        <h2 className="main-content-informatie-h2">Hoe werkt dit spiritueel weekend?</h2>
                        <p className="main-content-informatie-text">We zouden graag een stuk willen delen over een
                            bijzonder healing weekend met Ayahuasca, heart dancing, ademwerk en sharing.
                            <br></br><br></br>
                            Het is in deze intieme setting dat we de poorten openen naar de diepten van ons bewustzijn,
                            waar de Ayahuasca ons begeleidt op een innerlijke reis van transformatie en genezing. Met
                            elke slok van dit oude sjamanistische elixer voelen we ons verbonden met de eeuwenoude
                            wijsheid van de Amazone.
                            <br></br><br></br>
                            Ademwerk dient als een krachtig hulpmiddel om ons te bevrijden van oude patronen en
                            beperkingen. We ademen diep in en uit, waardoor blokkades worden losgelaten en we ruimte
                            creëren voor helende energie om door ons heen te stromen. Het is een dans van adem en
                            bewustzijn die ons naar diepere lagen van ons wezen leidt.
                            <br></br><br></br>
                            Tijdens de sharing delen we onze ervaringen, inzichten en kwetsbaarheid met elkaar. We
                            worden een gemeenschap van zielen die elkaar ondersteunen en begrijpen. Het delen van onze
                            reis maakt de ervaring compleet en versterkt het helende proces.
                            <br></br><br></br>
                            In deze magische setting komen we samen als individuen, maar vertrekken we als een geheel.
                            We worden herinnerd aan de oneindige mogelijkheden die in ons verscholen liggen. Het is een
                            ervaring die ons transformeert, ons inzicht geeft en ons leidt naar een dieper begrip van
                            onszelf en de wereld om ons heen.
                            <br></br><br></br>
                            Dit healing weekend met Ayahuasca, oefeningen vanuit het lichaam emotie en hart, ademwerk en
                            sharing is een bijna heilig ritueel dat ons uitnodigt om ons te verbinden met onze essentie,
                            onze waarheid en onze potentie. Het is een ontdekkingsreis die ons herinnert aan de
                            verbondenheid van alles en de helende kracht die in ons allen schuilt.
                        </p>
                    </div>
                </div>
                <div className="main-content-information-third">
                    <div className="main-content-informatie-voorwie-container">
                        <div className="main-content-informatie-voorwie-container-text">
                            <h2 className="main-content-informatie-h2-wie">Wie kunnen aan de Ayahuasca ceremonie?</h2>
                            <p className="main-content-informatie-text-wie">Hoewel de gelegenheid om de liefde, de
                                teachings, de inzichten en het licht van dit spirituele medicijn te ontvangen openstaat
                                voor iedereen, is het niet voor iedereen geschikt.
                                <br></br><br></br>
                                Daarom beoordelen we dit per individu. Het is van groot belang dat je ons op de hoogte
                                brengt als je medicijnen gebruikt of als je last hebt van gezondheidsproblemen, zoals
                                hoge bloeddruk, depressie, borderline, bipolaire stoornissen of andere psychische of
                                lichamelijke aandoeningen.
                                <br></br><br></br>
                                Deze bijzondere ervaring is specifiek voor jou bedoeld, en hoewel we van onze familie
                                houden en hen respecteren, is het belangrijk dat je deze ervaring zo veel mogelijk
                                alleen aangaat, zodat je niet afgeleid wordt of getriggerd wordt door hen tijdens het
                                proces. Daarom vragen we dat jonge kinderen, baby’s, en huisdieren thuis blijven.</p>
                        </div>
                        <img className="main-content-informatie-image" src={infoImage} alt='123'></img>
                    </div>
                </div>
                <div className="main-content-information-fourth">
                    <div className="main-content-informatie-hoewerkt-container">
                        <h2 className="main-content-informatie-h2-wie">Ayahuasca Nederland Ceremonie
                            <br></br>Een levensveranderende ervaring!</h2>
                        <br></br>
                        <div onClick={mainContentOnClick} className="btn-main-content">
                            <div className="btn-main-content-text">Book a ceremonie</div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    } else if (language === 'en') {
        return (
            <div className="main-background">
                <div className="main-content-rectangle">
                    <div className="main-content-container">
                        <h1 className="main-h1">
                            Ayahuasca Netherlands
                            Healing Ceremony
                        </h1>
                        <div className="main-buttons-container">
                            <div onClick={mainContentOnClick} className="btn-main-content">
                                <div className="btn-main-content-text">Book a ceremony</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-content-information">
                    <div className="main-content-informatie-ceremonie-container">
                        <h2 className="main-content-informatie-h2">The ceremony</h2>
                        <p className="main-content-informatie-text">We are the best provider of
                            Ayahuasca ceremonies. Not only the Ayahuasca is important, but also the setting in which the
                            Ayahuasca is used. In addition, professional preparation and integration is important so
                            that you get the best and complete experience possible. Led by highly experienced counselors
                            who have been working with the mother plant for more than 12 years in the Netherlands and
                            abroad and a qualified psychologist, our ceremonies provide a safe, supportive and nurturing
                            environment for personal growth, healing and spiritual exploration.
                            <br></br><br></br>
                            What makes the difference with other providers; we offer a total concept. A thorough
                            psychological explanation of what awaits you. Sharing and exercises to better connect with
                            yourself and others. Professional breathwork to clear energy blockages so that the Ayahuasca
                            can do its work even better and deeper. Sharing and professional tips and tricks to initiate
                            the integration process properly and thoroughly.
                            <br></br><br></br>
                            Embark on a transformative journey with our Ayahuasca ceremonies and unlock the potential
                            for healing, self-discovery and personal growth. Join us as we delve into the ancient wisdom
                            of Ayahuasca and embrace the profound possibilities it offers.
                            <br></br><br></br>
                            Register today for our Ayahuasca healing weekends in the Netherlands.
                        </p>
                    </div>
                    <div className="main-content-informatie-overons-container">
                        <h2 className="main-content-informatie-h2">About us</h2>
                        <p className="main-content-informatie-text">After more than 12 years, the sacred journey has stopped facilitating Healing weekends with ayahuasca . It is with great pleasure that we have respected and guided these ceremonies from our hearts all these years. That is why we continue these ceremonies in the spirit of Lars and Tanja Faber of www.dewijdereis.nl.
                            <br></br><br></br>Love, Petra, Tjeerd, Elena and Rob
                        </p>
                    </div>
                </div>

                <div className="main-content-information-second">
                    <div className="main-content-informatie-hoewerkt-container">
                        <h2 className="main-content-informatie-h2">How does this spiritual weekend work?</h2>
                        <p className="main-content-informatie-text">We would like to share a piece about a special healing weekend with Ayahuasca, heart dancing, breathwork and sharing.
                            <br></br><br></br>
                            It is in this intimate setting that we open the gates to the depths of our consciousness, where Ayahuasca guides us on an inner journey of transformation and healing. With every sip of this ancient shamanic elixir we feel connected to the ancient wisdom of the Amazon.
                            <br></br><br></br>
                            Breathwork serves as a powerful tool to free us from old patterns and limitations. We breathe deeply in and out, releasing blockages and creating space for healing energy to flow through us. It is a dance of breath and consciousness that leads us to deeper layers of our being.
                            <br></br><br></br>
                            During sharing we share our experiences, insights and vulnerability with each other. We become a community of souls who support and understand each other. Sharing our journey completes the experience and strengthens the healing process.
                            <br></br><br></br>
                            In this magical setting we come together as individuals, but leave as a whole. We are reminded of the infinite possibilities hidden within us. It is an experience that transforms us, gives us insight and leads us to a deeper understanding of ourselves and the world around us.
                            <br></br><br></br>
                            This healing weekend with Ayahuasca, exercises from the body, emotion and heart, breathwork and sharing is an almost sacred ritual that invites us to connect with our essence, our truth and our potential. It is a journey of discovery that reminds us of the interconnectedness of everything and the healing power that lies within us all.
                        </p>
                    </div>
                </div>
                <div className="main-content-information-third">
                    <div className="main-content-informatie-voorwie-container">
                        <div className="main-content-informatie-voorwie-container-text">
                            <h2 className="main-content-informatie-h2-wie">Who can participate in the Ayahuasca ceremony?</h2>
                            <p className="main-content-informatie-text-wie">While the opportunity to receive the love, teachings, insights and light of this spiritual medicine is open to everyone, it is not for everyone.
                                <br></br><br></br>
                                That is why we assess this per individual. It is very important that you inform us if you are taking medication or if you suffer from health problems, such as high blood pressure, depression, borderline, bipolar disorders or other psychological or physical conditions.
                                <br></br><br></br>
                                This special experience is specifically for you, and while we love and respect our family, it is important that you undertake this experience alone as much as possible so that you are not distracted or triggered by them during the process. That's why we ask that young children, babies, and pets stay home.</p>
                        </div>
                        <img className="main-content-informatie-image" src={infoImage} alt='123'></img>
                    </div>
                </div>
                <div className="main-content-information-fourth">
                    <div className="main-content-informatie-hoewerkt-container">
                        <h2 className="main-content-informatie-h2-wie">Ayahuasca Netherlands Ceremony
                            <br></br>A life-changing experience!</h2>
                        <br></br>
                        <div onClick={mainContentOnClick} className="btn-main-content">
                            <div className="btn-main-content-text">Book a ceremony</div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }

}

export default MainContentComponent;
