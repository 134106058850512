// A component that contains the footer of the website.

import React from "react";

function FooterComponent() {

    const currentYear = new Date().getFullYear();

    return (
        <div>
            <div className="footer-container">
                <div className="footer-text-container">
                    <a href="/#">
                        <div className="logo">
                            <div className="logo-text-footer">Ayahuasca Nederland</div>
                        </div>
                    </a>
                    <p className="footer-text">
                        Copyright © {currentYear} Ayahuasca Nederland. All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default FooterComponent;
