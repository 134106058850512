import './App.css';
import NavbarComponent from "./Components/NavbarComponent";
import FooterComponent from "./Components/FooterComponent";
import MainContentComponent from "./Components/MainContentComponent";

function App() {
  return (
    <div className="App">
        <header>
            <NavbarComponent />
        </header>
        <MainContentComponent />
    </div>
  );
}

export default App;
