import * as React from "react";
import { createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import App from "./App";
import AgendaPage from "./Pages/AgendaPage";
import ContactPage from "./Pages/ContactPage";
import VoorbereidingPage from "./Pages/VoorbereidingPage";
import QuestionsPage from "./Pages/QuestionsPage";
import AboutPage from "./Pages/AboutPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <App />
        ),

    },
    {
        path: "/agenda",
        element: (
            <AgendaPage />
        ),
    },
    {
        path: "/contact",
        element: (
            <ContactPage />
        ),
    },
    {
        path: "/voorbereding",
        element: (
            <VoorbereidingPage />
        ),
    },
    {
        path: "/faq",
        element: (
            <QuestionsPage />
        ),
    },
    {
        path: '/about',
        element: (
            <AboutPage />
        ),
    }
]);

createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
);