import '../App.css';
import NavbarComponent from "../Components/NavbarComponent";
import FooterComponent from "../Components/FooterComponent";
import QuestionsMainContent from "../Components/QuestionsMainContent";
import VoorbereidingMainContent from "../Components/VoorbereidingMainContent";

function VoorbereidingPage() {
    return (
        <div className="App">
            <header>
                <NavbarComponent />
            </header>
            <QuestionsMainContent />
            <FooterComponent />
        </div>
    );
}

export default VoorbereidingPage;