// A component that contains the main content of the main page.

import React, {useEffect, useState} from "react";
import AccordionGroup, { AccordionGroupProps } from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import Button from '@mui/joy/Button';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';
import Stack from '@mui/joy/Stack';
import {Alert} from "@mui/joy";

function QuestionsMainContent() {

    const [language, setLanguage] = useState('en');

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            setLanguage(storedLanguage);
        }
    }, []);

    if (language === 'nl') {
        return (
            <div className="main-background-agenda">
                <div className="agenda-container">
                    <div className="contact-container">
                        <h2 className="contact-h2">Over ons</h2>
                        <br></br>
                        <p className='main-content-informatie-text'>Wij organiseren en begeleiden kleinschalige Ayahuasca weekenden in de geest van Lars en Tanja van <a
                                className='voorbereiding-informatie-text-hyperlink'
                                href='www.degewijdereis.nl'>www.degewijdereis.nl</a> vanwege onze fijne connectie met hun. Zij besloten te stoppen met Ayahuasca en wij vonden het zo jammer dat wij voor ons zelf begonnen zijn. Uiteraard ook met een vleugje van ons zelf. Wat voor ons heel belangrijk is om een zo compleet mogelijk weekend aan te bieden zodat de deelnemers met een toolbox uit zijn gerust om zeer goed voorbereid de reis in te gaan  en naderhand het zo goed te kunnen integreren. liefs Petra, Elena, Tjeerd en Rob</p>
                        <br></br><br></br>
                        <div className="about-us-quote-container">
                            <div className="about-us-quote">
                                <p className="about-us-quote-text">"Steeds meer leven vanuit je hart. Onvoorwaardelijke
                                    liefde voor jezelf en van daar uit connecten met medemensen."</p>
                            </div>
                        </div>
                        <br></br><br></br>
                        <p className='main-content-informatie-text'>Vele jaren ervaring heeft ervoor gezorgd dat wij totale
                            Ayahuasca ceremonies aanbieden. Met totaal bedoelen wij een veilige warme liefdevolle setting.
                            Dit is essentieel voor een goede ervaring met ”de Madre”, de moederplant. Dus niet alleen
                            Ayahuasca drinken maar eerst connecten met elkaar dmv lichaams- en emotie oefeningen voor een
                            veilige groepsbinding. Dan ademwerk om je systeem open te zetten. Daardoor kan de Ayahuasca veel
                            beter binnenkomen en kan de healing veel dieper binnenkomen en permanenter aarden in jezelf.</p>
                    </div>
                </div>
            </div>
        );
    } else if (language === 'en') {
        return (
            <div className="main-background-agenda">
                <div className="agenda-container">
                    <div className="contact-container">
                        <h2 className="contact-h2">About Us</h2>
                        <br></br>
                        <p className='main-content-informatie-text'>We organize and guide small-scale Ayahuasca weekends in the spirit of Lars and Tanja from <a
                            className='voorbereiding-informatie-text-hyperlink'
                            href='www.degewijdereis.nl'>www.degewijdereis.nl</a>. They decided to stop doing Ayahuasca and we were so sad that we started for ourselves. Of course also with a touch of our own. What is very important to us is to offer a weekend as complete as possible so that the participants are equipped with a toolbox to go into the journey well prepared and afterwards be able to integrate it so well. loves Petra, Elena, Tjeerd and Rob.</p>
                        <br></br><br></br>
                        <div className="about-us-quote-container">
                            <div className="about-us-quote">
                                <p className="about-us-quote-text">"Living more and more from your heart. Unconditional love for yourself and from there connecting with fellow human beings."</p>
                            </div>
                        </div>
                        <br></br><br></br>
                        <p className='main-content-informatie-text'>Many years of experience have ensured that we offer total Ayahuasca ceremonies. By total we mean a safe, warm, loving setting. This is essential for a good experience with “de Madre”, the mother plant. So don't just drink Ayahuasca, but first connect with each other through body and emotion exercises for safe group bonding. Then breathwork to open your system. This allows the Ayahuasca to enter much better and the healing to enter much deeper and ground itself more permanently in yourself.</p>
                    </div>
                </div>
            </div>
        );
    }

}

export default QuestionsMainContent;
