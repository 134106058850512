// A component that contains the main content of the main page.

import React, {useEffect, useState} from "react";
import AccordionGroup, { AccordionGroupProps } from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import Button from '@mui/joy/Button';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';
import Stack from '@mui/joy/Stack';

function QuestionsMainContent() {

    // State to hold the current language
    const [language, setLanguage] = useState('en'); // Default to English

    // Effect to read the language setting from localStorage on initial load
    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            setLanguage(storedLanguage);
        }
    }, []);

    if (language === 'nl') {
        return (
            <div className="main-background-agenda">
                <div className="agenda-container">
                    <div className="contact-container">
                        <h2 className="contact-h2">FAQ</h2>
                        <AccordionGroup size='lg'>
                            <Accordion>
                                <AccordionSummary className='main-content-informatie-text'>Waarom moeten mensen van Ayahuasca altijd overgeven?</AccordionSummary>
                                <AccordionDetails className='main-content-informatie-text'>
                                    Je hoeft niet altijd over te geven met ayahuasca, er zijn soms ceremonies waarbij 50% overgeeft, soms waarbij bijna niemand overgeeft en soms waarbij bijna iedereen overgeeft. Wat interessant is dat het in het Amazone gebied “La Purga” wordt genoemd, Nederlandse vertaling “de zuivering”, een soort energetische ontlading van negatieve energie.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary className='main-content-informatie-text'>Heb ik een dieet nodig voor ayahuasca?</AccordionSummary>
                                <AccordionDetails className='main-content-informatie-text'>
                                    Voor Ayahuasca heb je een dieet nodig vanwege de MAO remmer die het bevat. Dat gaat niet goed samen met bepaalde voedingsmiddelen. De MAO remmer kan ook met bepaalde medicijnen of drugs zelfs levensgevaarlijke complicaties opleveren.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary className='main-content-informatie-text'>Hoeveel dagen duurt een retraite?</AccordionSummary>
                                <AccordionDetails className='main-content-informatie-text'>
                                    Een retraite bij ons is van vrijdag avond tot zondag middag. Bij individuele ayahuasca ceremonies is het vaak 1 dag. Maar bij groeps ceremonies is het belangrijk om een warme veilige liefdevolle setting te creeeren. En door sharing , ademwerk en integratie kan je veel meer uit je reis halen.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary className='main-content-informatie-text'>Wat kun je leren van ayahuasca?</AccordionSummary>
                                <AccordionDetails className='main-content-informatie-text'>
                                    Wat je leert van Ayahuasca is zeer divers en voor iedereen uniek. De rode draad is dat Moeder Ayahuasca (La Madre) er altijd is om je te helpen. Soms kan ze je in 1 keer genezen, meestal laat ze zien waar je aan te werken heb. Dat kan je dan door bijvoorbeeld innerlijk kind werk voortzetten en transformeren.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary className='main-content-informatie-text'>Waarom Ayahuasca in groepsverband?</AccordionSummary>
                                <AccordionDetails className='main-content-informatie-text'>
                                    Je kunt niet de muziek bepalen en de begeleiders zijn er niet alleen voor jou maar
                                    reizen in een groep heeft een hoop voordelen.
                                    Allereerst is het fijn om met andere gelijk gestemde te connecten. Ook de sharing
                                    voor en na de ceremonie kan je verschillende inzichten geven over je eigen
                                    ontwikkeling. Maar het grootste voordeel is wel dat je gezamenlijk het Ayahuasca
                                    energie veld neerzet waardoor het krachtiger wordt en daardoor kan 1 + 1 = 3 worden.
                                    Andere reizigers kunnen voor jou patronen doorbreken. In de sharing hebben we vaak
                                    gehoord dingen als ‘’door jou huilen kon ik eindelijk ook bij mijn eigen verdriet.”
                                    “Door jou lachen kon ik mijn trauma veel beter handelen.” “Het was alsof door jou
                                    overgave ik ook alles beter kon loslaten.”
                                    <br/><br/>
                                    Uiteraard kan het ook gebeuren dat je jezelf “ergert” aan de emotionele toestand van
                                    een andere deelnemer. De uitnodiging is dan om dit te gebruiken als ingang voor je
                                    eigen reis. Door je zelf vragen te stellen als; Waarom triggert dat gehuil me?
                                    Wanneer in mijn leven begon ik me te ergeren aan lachen? Hoe oud was ik toen ik
                                    schrok van overgeven?
                                    Hierdoor kan je naar binnen gaan en kan je reis beginnen en misschien als het nodig
                                    is krijg je antwoorden op je vragen.
                                    <br/><br/>
                                    Uiteraard kun je altijd uitreiken naar een van de begeleiders om te kijken of zij
                                    jou verder kunnen helpen.
                                </AccordionDetails>
                            </Accordion>
                        </AccordionGroup>
                    </div>
                </div>
            </div>
        );
    } else if (language === 'en') {
        return (
            <div className="main-background-agenda">
                <div className="agenda-container">
                    <div className="contact-container">
                        <h2 className="contact-h2">FAQ</h2>
                        <AccordionGroup size='lg'>
                            <Accordion>
                                <AccordionSummary className='main-content-informatie-text'>Why do Ayahuasca people always have to vomit?</AccordionSummary>
                                <AccordionDetails className='main-content-informatie-text'>
                                    You don't always have to vomit with ayahuasca, there are sometimes ceremonies where 50% vomit, sometimes where almost no one vomits and sometimes where almost everyone vomits. What is interesting is that in the Amazon region it is called “La Purga”, English translation “the purification”, a kind of energetic discharge of negative energy.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary className='main-content-informatie-text'>Do I need a diet for ayahuasca?</AccordionSummary>
                                <AccordionDetails className='main-content-informatie-text'>
                                    You need a diet for Ayahuasca because of the MAO inhibitor it contains. This does not go well with certain foods. The MAO inhibitor can even cause life-threatening complications with certain medications or drugs.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary className='main-content-informatie-text'>How many days does a retreat last?</AccordionSummary>
                                <AccordionDetails className='main-content-informatie-text'>
                                    A retreat with us is from Friday evening to Sunday afternoon. For individual ayahuasca ceremonies it is often 1 day. But in group ceremonies it is important to create a warm, safe, loving setting. And through sharing, breathwork and integration you can get much more out of your journey.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary className='main-content-informatie-text'>What can you learn from ayahuasca?</AccordionSummary>
                                <AccordionDetails className='main-content-informatie-text'>
                                    What you learn from Ayahuasca is very diverse and unique for everyone. The common thread is that Mother Ayahuasca (La Madre) is always there to help you. Sometimes she can heal you in one go, usually she shows you what you need to work on. You can then continue and transform this through, for example, inner child work.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary className='main-content-informatie-text'>Why do Ayahuasca in groups?</AccordionSummary>
                                <AccordionDetails className='main-content-informatie-text'>
                                    You can't control the music and the guides aren't there just for you but traveling
                                    in a group has a lot of advantages.
                                    First of all, it is nice to connect with other like-minded people. Also, the sharing
                                    before and after the ceremony can give you different insights about your own
                                    development. But the biggest advantage is that together you establish the Ayahuasca
                                    energy field which makes it more powerful and therefore 1 + 1 = 3. Other travelers
                                    can break patterns for you. In the sharing we have often heard things like ''Because
                                    of you crying I could finally reach my own sadness too.” “Through you laughing I was
                                    able to handle my trauma much better.” “It was like through you surrendering I could
                                    also let go of everything better.”
                                    <br/><br/>
                                    Of course, you may also find yourself “annoyed” by another participant's emotional
                                    state. The invitation then is to use this as an entry point for your own journey. By
                                    asking yourself questions such as; Why does that crying trigger me? When in my life
                                    did I start getting annoyed by laughter? How old was I when I was startled by
                                    vomiting?
                                    This allows you to go inward and your journey can begin and maybe if needed you will
                                    get answers to your questions.
                                    <br/><br/>
                                    And of course, you can always reach out to one of the guides to see if they can help
                                    you further.
                                </AccordionDetails>
                            </Accordion>
                        </AccordionGroup>
                    </div>
                </div>
            </div>
        );
    }

}

export default QuestionsMainContent;
