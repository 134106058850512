import '../App.css';
import NavbarComponent from "../Components/NavbarComponent";
import FooterComponent from "../Components/FooterComponent";
import ContactMainContent from "../Components/ContactMainContent";

function ContactPage() {
    return (
        <div className="App">
            <header>
                <NavbarComponent />
            </header>
            <ContactMainContent />
            <FooterComponent />
        </div>
    );
}

export default ContactPage;